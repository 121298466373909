import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext.js';

const Cart = () => {
    const navigate = useNavigate();
    const { cartItems, updateQuantity, removeFromCart, shippingDetails, updateShippingDetails, totalCost, isShippingCalculated } = useCart();

    const subtotal = cartItems.reduce((total, item) =>
        total + (item.price * item.quantity), 0
    );

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        updateShippingDetails({ [name]: value });
    };

    const isProceedToCheckoutDisabled = !isShippingCalculated; // Disabled if shipping is not calculated

    if (cartItems.length === 0) {
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <div className="text-center">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">
                        Your cart is empty
                    </h2>
                    <button
                        onClick={() => navigate('/shop')}
                        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                    >
                        Continue Shopping
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Shopping Cart</h1>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start">
                <div className="lg:col-span-7">
                    {cartItems.map((item) => (
                        <div key={item.id} className="flex py-6 border-b border-gray-200">
                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                <img
                                    src={item.image || 'https://via.placeholder.com/150'}
                                    alt={item.name}
                                    className="h-full w-full object-cover object-center"
                                />
                            </div>
                            <div className="ml-4 flex flex-1 flex-col">
                                <div className="flex justify-between text-base font-medium text-gray-900">
                                    <h3>{item.name}</h3>
                                    <p className="ml-4">R {(item.price * item.quantity).toFixed(2)}</p>
                                </div>
                                <div className="mt-2 flex items-center justify-between text-sm">
                                    <p className="text-gray-500">
                                        Quantity: {item.quantity}
                                    </p>
                                    <div className="flex">
                                        <button
                                            onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                            +
                                        </button>
                                        <button
                                            onClick={() => updateQuantity(item.id, item.quantity - 1)}
                                            className="font-medium text-indigo-600 hover:text-indigo-500 mx-2"
                                        >
                                            -
                                        </button>
                                        <button
                                            onClick={() => removeFromCart(item.id)}
                                            className="font-medium text-red-600 hover:text-red-500"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="lg:col-span-5">
                    <div className="bg-white border border-gray-200 rounded-md p-6">
                        <div className="space-y-6">
                            <div>
                                <label htmlFor="streetAddress" className="block text-sm font-medium text-gray-700">
                                    Street Address
                                </label>
                                <input
                                    type="text"
                                    name="streetAddress"
                                    id="streetAddress"
                                    value={shippingDetails.streetAddress || ''}
                                    onChange={handleAddressChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={shippingDetails.city}
                                    onChange={handleAddressChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
                                    Postal Code
                                </label>
                                <input
                                    type="text"
                                    name="postalCode"
                                    id="postalCode"
                                    value={shippingDetails.postalCode}
                                    onChange={handleAddressChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="province" className="block text-sm font-medium text-gray-700">
                                    Province
                                </label>
                                <select
                                    name="province"
                                    id="province"
                                    value={shippingDetails.province}
                                    onChange={handleAddressChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                >
                                    <option value="">Select Province</option>
                                    <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                    <option value="Gauteng">Gauteng</option>
                                    <option value="Western Cape">Western Cape</option>
                                    <option value="Eastern Cape">Eastern Cape</option>
                                    <option value="Northern Cape">Northern Cape</option>
                                    <option value="Free State">Free State</option>
                                    <option value="Mpumalanga">Mpumalanga</option>
                                    <option value="Limpopo">Limpopo</option>
                                    <option value="North West">North West</option>
                                </select>
                            </div>
                            <div className="flex justify-between">
                                <p className="text-gray-900 font-bold">Subtotal:</p>
                                <p className="text-gray-900">R {subtotal.toFixed(2)}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="text-gray-900 font-bold">Shipping:</p>
                                <p className="text-gray-900">R {shippingDetails.cost ? shippingDetails.cost.toFixed(2) : '0.00'}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="text-lg font-semibold text-gray-900">Total:</p>
                                <p className="text-lg font-semibold text-gray-900">R {totalCost.toFixed(2)}</p>
                            </div>
                            <button
    onClick={() => navigate('/checkout', { state: { totalCost } })}
    disabled={isProceedToCheckoutDisabled}
    className="mt-4 inline-flex items-center justify-center w-full py-3 px-6 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
>
    Proceed to Checkout
</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;
