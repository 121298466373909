import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const Checkout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { cartItems, totalCost, shippingDetails, clearCart } = useCart();
    const [yocoLoaded, setYocoLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
    });

    // South African-specific validation functions
    const validateSAPhoneNumber = (phone) => {
        // Validate South African phone number formats
        const phoneRegex = /^((\+27|27)|0)(\d{9})$/;
        return phoneRegex.test(phone.replace(/\s/g, ''));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validatePersonalInfo = () => {
        const errors = {};

        // First Name validation
        if (!personalInfo.firstName.trim()) {
            errors.firstName = 'First name is required';
        } else if (personalInfo.firstName.trim().length < 2) {
            errors.firstName = 'First name must be at least 2 characters long';
        }

        // Last Name validation
        if (!personalInfo.lastName.trim()) {
            errors.lastName = 'Last name is required';
        } else if (personalInfo.lastName.trim().length < 2) {
            errors.lastName = 'Last name must be at least 2 characters long';
        }

        // Email validation
        if (!personalInfo.email.trim()) {
            errors.email = 'Email is required';
        } else if (!validateEmail(personalInfo.email)) {
            errors.email = 'Invalid email format';
        }

        // Phone Number validation
        if (!personalInfo.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone number is required';
        } else if (!validateSAPhoneNumber(personalInfo.phoneNumber)) {
            errors.phoneNumber = 'Invalid South African phone number format';
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        // Dynamically load Yoco SDK
        const script = document.createElement('script');
        script.src = 'https://js.yoco.com/sdk/v1/yoco-sdk-web.js';
        script.async = true;
        script.onload = () => setYocoLoaded(true);
        script.onerror = () => setError('Failed to load Yoco SDK');
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handlePersonalInfoChange = (e) => {
        const { name, value } = e.target;
        setPersonalInfo(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear specific field error when user starts typing
        if (validationErrors[name]) {
            setValidationErrors(prev => ({
                ...prev,
                [name]: undefined
            }));
        }
    };

    const handlePayment = () => {
        // Validate personal info before proceeding
        if (!validatePersonalInfo()) {
            return;
        }

        if (!window.YocoSDK) {
            setError('Yoco SDK not loaded');
            return;
        }

        const sdk = new window.YocoSDK({
            publicKey: 'pk_live_dc5e0de4jVdA3r092514'
        });

        sdk.showPopup({
            amountInCents: Math.round(totalCost * 100),
            currency: 'ZAR',
            name: '1Africa Shop',
            description: `Order with ${cartItems.length} items`,
            callback: async (result) => {
                if (result.successful) {
                    try {
                        // Generate unique order number
                        const orderNumber = `ORDER-${new Date().getFullYear()}-${uuidv4().slice(0,8).toUpperCase()}`;

                        // Prepare order details for Firestore
                        const orderDetails = {
                            orderNumber,
                            customerName: `${personalInfo.firstName} ${personalInfo.lastName}`,
                            email: personalInfo.email,
                            phoneNumber: personalInfo.phoneNumber,
                            shippingDetails: {
                                address: shippingDetails.streetAddress,
                                city: shippingDetails.city,
                                province: shippingDetails.province,
                                postalCode: shippingDetails.postalCode
                            },
                            items: cartItems.map(item => ({
                                id: item.id,
                                name: item.name,
                                quantity: item.quantity,
                                price: item.price
                            })),
                            subtotal: totalCost - shippingDetails.cost,
                            shippingCost: shippingDetails.cost,
                            total: totalCost,
                            tax: totalCost * 0.15, // 15% VAT assumption
                            status: 'Pending',
                            paymentToken: result.token,
                            createdAt: serverTimestamp(),
                            // Add additional logging metadata
                            clientMetadata: {
                                userAgent: navigator.userAgent,
                                screenResolution: `${window.screen.width}x${window.screen.height}`,
                                timestamp: new Date().toISOString()
                            }
                        };

                        // Save order to Firestore
                        const orderRef = await addDoc(collection(db, 'orders'), orderDetails);

                        // Clear cart after successful payment
                        clearCart();
                        
                        // Redirect to success page
                        navigate('/payment-success', { 
                            state: { 
                                orderId: orderRef.id, 
                                orderNumber: orderDetails.orderNumber,
                                amount: totalCost,
                                details: orderDetails
                            } 
                        });
                    } catch (error) {
                        console.error('Order creation error:', error);
                        setError('Failed to process order. Please contact support.');
                    }
                } else {
                    setError(result.error || 'Payment was not successful');
                }
            }
        });
    };

    // Validate checkout conditions
    const isCheckoutValid = cartItems.length > 0 && 
        shippingDetails.streetAddress && 
        shippingDetails.city && 
        shippingDetails.postalCode && 
        shippingDetails.province &&
        personalInfo.firstName &&
        personalInfo.lastName &&
        personalInfo.email &&
        personalInfo.phoneNumber &&
        Object.keys(validationErrors).length === 0;

    if (!location.state?.totalCost) {
        return <div>Invalid checkout access. Please go through cart.</div>;
    }

    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-2xl font-bold mb-6">Checkout</h1>
            
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    {error}
                </div>
            )}

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">First Name</label>
                        <input
                            type="text"
                            name="firstName"
                            value={personalInfo.firstName}
                            onChange={handlePersonalInfoChange}
                            className={`mt-1 block w-full border ${validationErrors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3`}
                            required
                        />
                        {validationErrors.firstName && (
                            <p className="text-red-500 text-xs mt-1">{validationErrors.firstName}</p>
                        )}
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Last Name</label>
                        <input
                            type="text"
                            name="lastName"
                            value={personalInfo.lastName}
                            onChange={handlePersonalInfoChange}
                            className={`mt-1 block w-full border ${validationErrors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3`}
                            required
                        />
                        {validationErrors.lastName && (
                            <p className="text-red-500 text-xs mt-1">{validationErrors.lastName}</p>
                        )}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={personalInfo.email}
                        onChange={handlePersonalInfoChange}
                        className={`mt-1 block w-full border ${validationErrors.email ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3`}
                        required
                    />
                    {validationErrors.email && (
                        <p className="text-red-500 text-xs mt-1">{validationErrors.email}</p>
                    )}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        value={personalInfo.phoneNumber}
                        onChange={handlePersonalInfoChange}
                        placeholder="e.g., +27 12 345 6789 or 012 345 6789"
                        className={`mt-1 block w-full border ${validationErrors.phoneNumber ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3`}
                        required
                    />
                    {validationErrors.phoneNumber && (
                        <p className="text-red-500 text-xs mt-1">{validationErrors.phoneNumber}</p>
                    )}
                </div>

                <h2 className="text-xl font-semibold mb-4">Shipping Details</h2>
                <div className="bg-gray-100 p-4 rounded-md mb-4">
                    <p>{shippingDetails.streetAddress}</p>
                    <p>{shippingDetails.city}, {shippingDetails.province} {shippingDetails.postalCode}</p>
                </div>

                <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
                {cartItems.map(item => (
                    <div key={item.id} className="flex justify-between mb-2">
                        <span>{item.name} x {item.quantity}</span>
                        <span>R {(item.price * item.quantity).toFixed(2)}</span>
                    </div>
                ))}
                <div className="border-t my-4"></div>
                <div className="flex justify-between font-bold">
                    <span>Shipping:</span>
                    <span>R {shippingDetails.cost.toFixed(2)}</span>
                </div>
                <div className="flex justify-between font-bold text-xl mt-4">
                    <span>Total:</span>
                    <span>R {totalCost.toFixed(2)}</span>
                </div>

                <button 
                    onClick={handlePayment}
                    disabled={!isCheckoutValid || !yocoLoaded}
                    className="w-full mt-6 bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 disabled:opacity-50"
                >
                    {!yocoLoaded ? 'Loading Payment...' : 'Pay Now'}
                </button>
            </div>
        </div>
    );
};

export default Checkout;