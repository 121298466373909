import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

const warehouseCoordinates = { lat: -29.8833, lon: 30.9833 };

export const calculateShipping = async (city, postalCode, province) => {
    try {
        // Check if the city is Durban and return a fixed cost of 100
        if (city.toLowerCase() === 'durban') {
            return 100;
        }

        const userCoordinates = await getCoordinates(`${city} ${postalCode}`);
        if (!userCoordinates) return 0;

        const distance = haversineDistance(warehouseCoordinates, userCoordinates);

        const provinceShippingRules = {
            "KwaZulu-Natal": { baseCost: 20, costPerKm: 5 },
            "Gauteng": { baseCost: 30, costPerKm: 7 },
            "Western Cape": { baseCost: 40, costPerKm: 6 },
            "Eastern Cape": { baseCost: 35, costPerKm: 6 },
            "Northern Cape": { baseCost: 50, costPerKm: 8 },
            "Free State": { baseCost: 25, costPerKm: 6 },
            "Mpumalanga": { baseCost: 30, costPerKm: 7 },
            "Limpopo": { baseCost: 35, costPerKm: 7 },
            "North West": { baseCost: 30, costPerKm: 6 },
        };

        const { baseCost, costPerKm } = provinceShippingRules[province] || { baseCost: 20, costPerKm: 5 };

        const shippingCost = baseCost + distance * costPerKm;

        return Math.min(shippingCost, 300); // Cap at 300
    } catch (error) {
        console.error('Error calculating shipping:', error);
        return 0;
    }
};

const getCoordinates = async (query) => {
    try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`);
        const data = await response.json();
        if (data.length > 0) {
            return { lat: parseFloat(data[0].lat), lon: parseFloat(data[0].lon) };
        }
    } catch (error) {
        console.error('Failed to fetch coordinates:', error);
    }
    return null;
};

const haversineDistance = (coord1, coord2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371;

    const dLat = toRad(coord2.lat - coord1.lat);
    const dLon = toRad(coord2.lon - coord1.lon);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(coord1.lat)) * Math.cos(toRad(coord2.lat)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
};

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const [shippingDetails, setShippingDetails] = useState({
        city: '',
        postalCode: '',
        province: '',
        cost: 0,
    });
    const [totalCost, setTotalCost] = useState(0);
    const [isShippingCalculated, setIsShippingCalculated] = useState(false);

    useEffect(() => {
        const updateShippingAndTotal = async () => {
            const subtotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

            if (shippingDetails.city && shippingDetails.postalCode && shippingDetails.province) {
                const shippingCost = await calculateShipping(
                    shippingDetails.city,
                    shippingDetails.postalCode,
                    shippingDetails.province
                );
                setShippingDetails((prev) => ({ ...prev, cost: shippingCost }));
                setTotalCost(subtotal + shippingCost);
                setIsShippingCalculated(true);
            } else {
                setShippingDetails((prev) => ({ ...prev, cost: 0 }));
                setTotalCost(subtotal);
                setIsShippingCalculated(false);
            }
        };

        updateShippingAndTotal();
    }, [cartItems, shippingDetails.city, shippingDetails.postalCode, shippingDetails.province]);

    const addToCart = (product, quantity = 1) => {
        setCartItems((prevItems) => {
            const existingItem = prevItems.find((item) => item.id === product.id);

            if (existingItem) {
                return prevItems.map((item) =>
                    item.id === product.id
                        ? { ...item, quantity: item.quantity + quantity }
                        : item
                );
            }

            return [...prevItems, { ...product, quantity }];
        });
    };

    const removeFromCart = (productId) => {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== productId));
    };

    const updateQuantity = (productId, quantity) => {
        setCartItems((prevItems) =>
            prevItems
                .map((item) =>
                    item.id === productId
                        ? { ...item, quantity: Math.max(0, quantity) }
                        : item
                )
                .filter((item) => item.quantity > 0)
        );
    };

    const updateShippingDetails = (details) => {
        setShippingDetails((prev) => ({ ...prev, ...details }));
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const value = {
        cartItems,
        shippingDetails,
        totalCost,
        isShippingCalculated, // New flag for shipping calculation status
        addToCart,
        removeFromCart,
        updateQuantity,
        updateShippingDetails,
        clearCart,
    };

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
