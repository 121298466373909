import { createContext, useContext, useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase.js';

const TestimonialContext = createContext();

export const useTestimonials = () => {
    return useContext(TestimonialContext);
};

export const TestimonialProvider = ({ children }) => {
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchTestimonials();
    }, []);

    const fetchTestimonials = async () => {
        try {
            const q = query(
                collection(db, 'testimonials'),
                orderBy('date', 'desc'),
                limit(3)
            );
            console.log('Fetching testimonials...');
            const querySnapshot = await getDocs(q);
            const testimonialData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                userName: doc.data().name
            }));
            console.log('Processed testimonials:', testimonialData);
            setTestimonials(testimonialData);
        } catch (error) {
            console.error('Error fetching testimonials:', error);
        } finally {
            setLoading(false);
        }
    };

    const value = {
        testimonials,
        loading,
        fetchTestimonials
    };

    return (
        <TestimonialContext.Provider value={value}>
            {children}
        </TestimonialContext.Provider>
    );
}; 